import React, { useEffect } from 'react'
import SEO from '../components/SEO'
import Header from '../components/header'
import Footer from '../components/footer'
import { loadScriptSrcAfterBody } from '../utils'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
const icons = [
  'Adobe',
  'Alteryx',
  'Atlassian',
  'Blackline',
  'BOX',
  'Cloudera',
  'Crowd strike holdings',
  'Docusign',
  'Dropbox',
  'Dynatrace',
  'Gsuite',
  'Github',
  'Hubspot',
  'Mongodb',
  'O365',
  'Okta',
  'Oracle',
  'Ringcentral',
  'Salesforce',
  'SAP',
  'Servicenow',
  'Shopify',
  'Skype',
  'Slack',
  'Square',
  'Twilio',
  'Webex',
  'Workday',
  'Xero',
  'Zendesk',
  'Zoho',
  'Zoom',
  'Zscaler',
  'Readthedocs',
  'Dzone',
  'Bytes',
  'Hongkiat',
  'InfoQ',
  'Daniweb',
  'sanger',
  'Youvisit',
  'Unigo',
  'Redditnc',
  'Niche',
  'DaFont',
  'Unsplash',
  'Dribbble',
  'Pixeden',
  'Site Inspire',
  'Designmodo',
  'GoToMeeting',
  'Excel',
  'OneNote',
  'Powerpoint',
  'Word',
  'Teams',
  'OneDrive',
  'slack',
  'cisco webex meetings',
  'Asana',
  'Outlook',
  'workday',
  'Zoho CRM',
  'Trello',
  'Teamviewer',
  'Citrix Workspace',
  'Basecamp 3',
  'Homebase',
  'BlueJeans',
  'Medium',
  'quora',
  'tinder',
  'WhatsApp',
  'Docker',
  'Jira',
  'Git',
  'Confluence',
  'Spotify',
  'Stripe',
  'Intercom',
  'Zenefit',
  'Gainsight',
  'Five9',
  'Infor',
  'Paycom',
  'Coupa',
  'Intuit',
  'Smartsheet',
  'Palantir',
  'Hortonworks',
  'Tableau',
  'Splunk',
  'New Relic',
  'Proofpoint',
  'fireeye',
  'CrowdStrike',
  'Datadog',
  'LogMeIn',
  'Godaydy',
  'Logility',
  'Castlight Health',
  'Cerner',
  'Medidate',
  'Veeva',
  'Athena health',
  'Guidewire',
  'Q2 Holdings',
  'Trade desk',
  'Channel Advisor',
  'Shopify',
  'RealPage',
  'Autodesk',
  'Mindbody',
  'Epicor',
  'Sage',
  'LinkedIn',
  'Adecco',
  'Recruit',
  'visier',
  'Qlikview',
  'Infusionsoft',
  'Markto',
  'GoodData',
  'Envoy',
  'Guidespark',
  'Checkr',
  'Greenhouse',
  'Anaplan',
  'Directly',
  'Yammer',
  'Domo',
  'Blinksale',
  'Pivotal Tracker',
  'Huddle',
  'FogBugz',
  'Mailchimp',
  'iContact',
  'Highrise',
  'NetSuite',
  'Expensify',
  'FreshBooks',
  'SurveyMonkey',
  'Mixpanel',
  'Varonis',
  'KissMeTric',
  'Chartbeat',
  'SuccessFactors',
  'Paylocity',
  'Xactly',
  'ProntoForms',
  'Doforms',
  'BOX',
  'Instruct',
  'AppFolio',
  'Wework',
  'Gusto',
  'ClearSlide',
  'Gigya',
  'Eloqua',
  'Buddy Media',
  'Zuberance',
  'SocialCast',
  'Vocus',
  'Bronto',
  'ShareFile',
  'Slideshare',
  'Scribd',
  'Taleo',
  'Intacct',
  'Kyriba',
  'Wave',
  'Zimbra',
  'Teambox',
  'Birst',
  'Lattice',
  'Wix',
  'Avalara',
  'Medallia',
  'Qualys',
  'Change Healthcare',
  'Appian',
  'J2 Global',
  'Mimecast',
  'Pluralsight',
  'Cornerstone OnDemand',
  'PagerDuty',
  'Mambu',
  'Marketo Engage',
  'Constant Contact',
  'Zuora',
  'Evernote',
  'Paychex',
  'Practice Fusion',
  'Lithium Technologies',
  'Halogen Software',
  'Mulesoft',
  'Apptio',
  'ServiceMax',
  'Centrify',
  'cVent',
  'SciQuest',
  'Apttus',
  'AppDynamics',
  'Cision',
  'ON24',
  'Blackbaud',
  'Opower',
  'Active Network',
  'Apigee',
  'Blackboard',
  'E2open',
  'Payscale',
  'Meltwater',
  'Eventbrite',
  'Synchronoss',
  'AdRoll',
  'Amber Road',
  'Carbonite',
  'Callidus',
  'Workfront',
  'Plex',
  'Instart',
  'Covisint',
  'Demandware',
  'InsideView',
  'LivePerson',
  'Healthstream',
  'ServiceSource',
  'Jive',
  'Aria',
  'Brightcove',
  'Intralinks',
  'Vend',
  'AeroHive',
  'LiveOps',
  'MYOB',
  'Clarizen',
  'Doximity',
  'Upwork',
  'AppDirect',
  'Cybozu',
  'Arena',
  'Deem',
  'Act-On',
  'SPS',
  'Demandbase',
  'BenefitFocus',
  'eGain',
  'Synygy',
  'Lattice',
  'Exact',
  'Hightail',
  'PROS',
  'EMC',
  'iCIMS',
  'Jobvite',
  'Fleetmatics',
  'Dell',
  'IMS Health',
  'Jitterbit',
  'BambooHR',
  'Retail Solutions',
  'DATEV eG',
  'Eagle',
  'Tidemark',
  '2U',
  'Logi Analytics',
  'inContact',
  'Dassault',
  'Ramco',
  'CollabNet',
  'Verint',
  'lQNavigator',
  'Accela',
  'CloudPay',
  'Hyland',
  'Namely',
  'OpenText',
  'Avangate',
  'AvidXchange',
  'CashStar',
  'Host Analytics',
  'Webtrends',
  'WhiteHat',
  'Ping Identity',
  'Pega',
  'Workforce',
  'WorkMarket',
  'FICO',
  'Symantec',
  'Parallels',
  'Glassdoor',
  'Opera',
  'SugarCRM',
  'Mindtree',
  'Mix Telematics',
  'Peoplefluent',
  'META4',
  '3D Systems',
  'Vmware',
  'Medidata Solutions',
  'QuestBack',
  'Support.com',
  'Alfresco',
  'Threatmetrix',
  'Bullhorn',
  'SilkRoad',
  'Actian',
  'Aconex',
  'Intel',
  'MindJet',
  'OrderDynamics',
  'Ceridian',
  'Nakisa',
  'Mobilelron',
  'BlackHawk',
  'Basware',
  'Motionsoft',
  'Tangoe',
  'RingLead',
  'RightScale',
  'Nuance',
  'Acumatica',
  'BMC',
  'Hitachi',
  'SolarWinds',
  'M-Files',
  'Ericsson',
  'Shopatron',
  'Qlik',
  'Wolters Kluwer',
  'QAD',
  'Lumesse',
  'NewVoiceMedia',
  'ASG',
  'Yardi',
  'Bazaarvoice',
  'Tibco',
  'Siemens',
  'Steelwedge',
  'Fujitsu',
  'HEAT',
  'Kronos',
  'MicroStrategy',
  'SAS',
  'AlphaBricks',
]
icons.sort((a: string, b: string) => a.localeCompare(b))
const bannerSetting = {
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  className: 'center',
  // autoplaySpeed: 5000,

  dots: true,

  rows: 5,
  slidesPerRow: 6,
  // autoplay: true,
}
export default function () {
  useEffect(() => {
    loadScriptSrcAfterBody('/turbo/index.js')
  }, [])
  return (
    <div className="turbo">
      <div>
        <SEO title="Turbo | Zenlayer" description="">
          <script src="/turbo-icons/flexible.js"></script>
          <link
            rel="stylesheet"
            type="text/css"
            href="/turbo-icons/common.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/turbo-icons/index.css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700"
            rel="stylesheet"
            type="text/css"
          />
        </SEO>
        <div className="bg bg-1"></div>
        {/* <div className="bg bg-2"></div> */}

        <div className="headerContainer">
          <Header theme="dark" />
        </div>
        <div className="page flex-col">
          <div className="box_1 flex-row justify-end">
            <div className="section_1 flex-col">
              <img
                onClick={() => {
                  location.assign('/turbo/')
                }}
                className="image_2"
                referrerPolicy="no-referrer"
                src=" /turbo-icons/img/SketchPng0dd19cb55292f3bdc93726b985b5d57d88591cff32ac6f3c3bc9fd4d49bcc649.png"
              />
              <span className="text_1">Speed up 350+ SaaS apps</span>
              <span className="paragraph_1">
                Acceleration that’s instant, secure, and in your control.
                <br />
                <br />
              </span>
            </div>
            <img
              className="image_3"
              referrerPolicy="no-referrer"
              src=" /turbo-icons/img/SketchPngdfc789b6c3a9a49fd92ea7944c3fca014fd9538a7d48c984c15ca64ba8cf8291.png"
            />
          </div>
          <div className="icons">
            <Slider {...bannerSetting}>
              {icons.map((i) => (
                <div>
                  <div className="icon-item">{i}</div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div style={{ background: '#323e48' }}>
        <div className="box_2 flex-col">
          <div className="block_3 flex-col">
            <span className="text_15">
              Turbo is compatible with the most popular operating systems on PCs
              and smartphones
            </span>
            <div className="list_1 flex-row">
              <div className="list-items_1-0 flex-col link" data-type="iphone">
                <div className="image-text_1-0 flex-col justify-between">
                  <img
                    className="image_5-0"
                    referrerPolicy="no-referrer"
                    src=" /turbo-icons/img/SketchPng14430e98a46e69f0625619ba2007bb968f988da0e715c8f9c3455093c923f58d.png"
                  />
                  <span className="text-group_1-0">iPhone</span>
                </div>
                <img
                  className="label_1-0"
                  referrerPolicy="no-referrer"
                  src=" /turbo-icons/img/SketchPng87dc9eb497a1d594fbf64d13cb1f1c7ed3c3dd2463f2697504881869d404106b.png"
                />
              </div>
              <div className="list-items_1-1 flex-col link" data-type="android">
                <div className="image-text_1-1 flex-col justify-between">
                  <img
                    className="image_5-1"
                    referrerPolicy="no-referrer"
                    src=" /turbo-icons/img/SketchPngc03ab313f01a4fc51568a99f15e898bd5f7fbd226c9f2824d1aff295269bd406.png"
                  />
                  <span className="text-group_1-1">Android</span>
                </div>
                <img
                  className="label_1-1"
                  referrerPolicy="no-referrer"
                  src=" /turbo-icons/img/SketchPng25748de131969b9a0883c2c2d44436d25fe78a673eff4f04ac65bb2c90ca32f9.png"
                />
              </div>
              <div className="list-items_1-2 flex-col link" data-type="macos">
                <div className="group_1-2 flex-col"></div>
                <span className="text_16-2">macOS</span>
                <img
                  className="label_1-2"
                  referrerPolicy="no-referrer"
                  src=" /turbo-icons/img/SketchPng25748de131969b9a0883c2c2d44436d25fe78a673eff4f04ac65bb2c90ca32f9.png"
                />
              </div>
              <div className="list-items_1-3 flex-col link" data-type="windows">
                <div className="image-text_1-3 flex-col justify-between">
                  <img
                    className="image_5-3"
                    referrerPolicy="no-referrer"
                    src=" /turbo-icons/img/SketchPnga7e35b403c571899d0b31c052d11b975a6b580b2189bb42938b67831d96cfdcf.png"
                  />
                  <span className="text-group_1-3">Windows</span>
                </div>
                <img
                  className="label_1-3"
                  referrerPolicy="no-referrer"
                  src=" /turbo-icons/img/SketchPng25748de131969b9a0883c2c2d44436d25fe78a673eff4f04ac65bb2c90ca32f9.png"
                />
              </div>
            </div>

            <div className="text-wrapper_14">
              <span className="text_17">Version 8.0.25 </span>
              <span className="text_18 link" data-type="dashboard">
                Log in to Turbo dashboard →
              </span>
            </div>
          </div>
          <div className="block_5 flex-col"></div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
